import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import qs from 'qs';

export type QueryType = { [key: string]: any };
type SetQueryType = (incomingQuery: QueryType) => void;

type UseQueryParamsReturn = [value: QueryType, setValue: SetQueryType];

function removeTimeFromUrl(url: string): string {
  const urlParams = new URLSearchParams(url);

  const removeTime = (dateString: string) => {
    return `${dateString.split('T')[0]}T00:00:00`;
  };

  if (urlParams.has('invoicingDate[start]')) {
    const startDate = urlParams.get('invoicingDate[start]');
    if (startDate) {
      urlParams.set('invoicingDate[start]', removeTime(startDate));
    }
  }

  if (urlParams.has('invoicingDate[end]')) {
    const endDate = urlParams.get('invoicingDate[end]');
    if (endDate) {
      urlParams.set('invoicingDate[end]', removeTime(endDate));
    }
  }

  return `?${urlParams.toString().replaceAll('%2C', ',')}`;
}

function useQueryParams(): UseQueryParamsReturn {
  const router = useRouter();

  const query = useMemo(() => {
    if (typeof window !== 'undefined') {
      return qs.parse(removeTimeFromUrl(window.location.search), {
        ignoreQueryPrefix: true,
        comma: true,
      });
    }
    return {};
  }, [router.query]);

  const setQuery: SetQueryType = useCallback(
    (incomingQuery) => {
      const nextQuery = { ...query, ...incomingQuery };

      if (nextQuery.invoicingDate) {
        if (nextQuery.invoicingDate.start)
          nextQuery.invoicingDate.start = `${
            nextQuery.invoicingDate.start.split('T')[0]
          }T00:00:00`;
        if (nextQuery.invoicingDate.end)
          nextQuery.invoicingDate.end = `${
            nextQuery.invoicingDate.end.split('T')[0]
          }T00:00:00`;
      }

      const nextQueryString = qs.stringify(nextQuery, {
        skipNulls: true,
        arrayFormat: 'comma',
        encode: false,
      });
      router.replace({
        pathname: router.asPath.split('?')[0],
        query: nextQueryString,
      });
    },
    [query]
  );

  return [query, setQuery];
}

export default useQueryParams;
