import React from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { Header, Text } from '@ui';
import Button from '@ui/Button';
import useQueryParams from '@src/hooks/useQueryParams';
import * as S from '@src/components/pages/Error404/Error404.styles';
import Page from '@shared/Page';

const NotFound = () => {
  const intl = useIntl();
  const router = useRouter();
  const [{ error }] = useQueryParams();

  return (
    <Page title="404">
      <S.Container>
        <S.Error>
          <label>404</label>
          <span role="img" aria-label="worried-face">
            😰
          </span>
        </S.Error>
        <Header as="h3">
          {intl.formatMessage({
            id: 'mz+UKo',
            defaultMessage: '¡Hum! No se encontró la página.',
          })}
        </Header>
        <Text>
          {intl.formatMessage({
            id: 'qO0Xnt',
            defaultMessage:
              'La página que estas buscando no existe o ha cambiado de lugar.',
          })}
        </Text>
        <pre>{error}</pre>
        <Button onClick={() => router.replace(`/`)}>
          {intl.formatMessage({
            id: '0jrhNQ',
            defaultMessage: 'Regresar al inicio',
          })}
        </Button>
      </S.Container>
    </Page>
  );
};

export default NotFound;
